import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import ScrollTo from 'src/components/ScrollTo'
import { WIDTH_MD } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'
import AutomaticPixJSON from '../../assets/AutomaticPix.json'
import { Section, Pix, Debit } from './style'
import MoneyEntry from '../../assets/images/money-entry.png'
import Add from '../../assets/images/add.png'

const AutomaticPix = () => {
  const width = useWidth()

  return (
    <Section id='pix-automatico' className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-5 col-xl-5 offset-md-1 offset-xl-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-28 lh-lg-44 text-graphite fw-700'>
              Pix Automático: Novo meio de pagamento
            </h2>
            <p className='fs-12 fs-md-14 fs-xl-16 lh-14 lh-md-17 lh-xl-20 mt-3 text-graphite fw-400'>
              O Pix Automático é o mais novo meio de pagamento que permite a realização de pagamentos de forma Automática e recorrente utilizando o
              Pix como meio de transação.
            </p>
            <p className='fs-12 fs-md-14 fs-xl-16 lh-14 lh-md-17 lh-xl-20 text-graphite fw-400'>
              Essa funcionalidade traz benefícios tanto para as empresas, que podem automatizar e agilizar a cobrança de seus produtos e serviços,
              como para os consumidores, que têm a comodidade de ter suas contas pagas de forma automatizada, evitando esquecimentos ou atrasos.
            </p>
          </div>
          <div className='col-md-5 col-xl-7 d-flex justify-content-center justify-content-lg-end mt-3 mt-md-0 mb-5'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-auto-dobra2/image.webp'
              altDescription='Notebook com a tela de gestão de pix Automático Inter Empresas.'
              arrayNumbers={[ 249, 296, 400, 679 ]}
            />
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-12 col-md-11 col-xl-12 offset-md-1 offset-xl-0 mb-3 mb-xl-4'>
            <h3 className='fs-14 fs-lg-20 fs-xl-28 lh-18 lh-lg-24 lh-xl-33 fw-700'>
              O Pix Automático é uma evolução do Débito Automático, conheça a diferença.
            </h3>
          </div>
          <div className='col-12 col-md-5 col-xl-6 offset-md-1 offset-xl-0 mb-3 mb-md-4'>
            <Pix className='px-3 px-xl-4 py-3'>
              <div className='mb-2'>
                <OrangeIcon size='MD' color='#FFFFFF' icon={AutomaticPixJSON[0].principalIcon} />
              </div>
              <div>
                <h4 className='fs-22 fs-lg-28 fs-xl-40 lh-24 lh-lg-32 lh-xl-44 fw-700 text-white'>
                  {AutomaticPixJSON[0].title}
                </h4>
              </div>
              {AutomaticPixJSON[0].titlePix.map((item: { icon: string; text: string }, index: number) => (
                <div key={index}>
                  <div className='d-flex align-items-center mb-xl-2'>
                    <div className='mr-2'>
                      {
                        item.icon === ''
                        ? <img src={MoneyEntry} />
                        : <OrangeIcon size='SM' color='#FFFFFF' icon={item.icon} />
                      }
                    </div>
                    <div>
                      <p className='text-white mb-0'>{item.text}</p>
                    </div>
                  </div>
                </div>
                  ))
                }
            </Pix>
          </div>
          <div className='col-12 col-md-5 col-xl-6'>
            <Debit className='px-3 px-xl-4 py-3'>
              <div className='mb-2'>
                <OrangeIcon size='MD' color='#9A4517' icon={AutomaticPixJSON[1].principalIcon} />
              </div>
              <div>
                <h4 className='fs-22 fs-lg-28 fs-xl-40 lh-24 lh-lg-32 lh-xl-44 fw-700'>
                  {AutomaticPixJSON[1].title}
                </h4>
              </div>
              {AutomaticPixJSON[1]?.titlePix?.map((item: { icon: string; text: string }, index: number) => (
                <div key={index}>
                  <div className='d-flex align-items-center mb-xl-2'>
                    <div className='mr-2'>
                      {
                        item.icon === ''
                        ? <img src={Add} />
                        : <OrangeIcon size='SM' color='#9A4517' icon={item.icon} />
                      }
                    </div>
                    <div>
                      <p className='mb-0'>{item.text}</p>
                    </div>
                  </div>
                </div>
                  ))
                }
            </Debit>
          </div>
          {width >= WIDTH_MD && (
            <div className='d-flex justify-content-center w-100'>
              <ScrollTo
                to='#o-que-muda'
                title='Saiba mais'
                section='dobra_02'
                sectionName='Pix Automático: Novo meio de pagamento'
                elementName='Saiba mais'
              >
                <button className='btn btn--lg btn-orange--extra text-white text-none mt-3'>
                  Saiba mais
                </button>
              </ScrollTo>
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}

export default AutomaticPix
