import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import AdvantagesJSON from '../../assets/Advantages.json'
import { Section, Card, CardWrapper } from './style'
import { WIDTH_MD } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Handshake from '../../assets/images/handshake.png'

type AdvantagesProps = {
  icon: string;
  title: string;
  description: string;
}

type ComoFuncionaProps = {
  setModalOpen: Function;
}

const advantagesToReceiveInter = ({ setModalOpen }: ComoFuncionaProps) => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section
      role='img'
      aria-label='Empresários sorridentes olhando para um computador em uma sala de reunião.'
      className='py-5 d-flex align-items-center'
    >
      <div className='container'>
        <div className='row align-items-start align-items-md-center mb-md-3 mt-xl-5'>
          <div className='col-12 col-md-11 offset-md-1 offset-xl-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-28 lh-lg-44 fw-700 font-citrina text-md-center mb-4'>
              Vantagens de receber através da API Pix
            </h2>
          </div>
        </div>
        <CardWrapper className='row col-md-11 col-xl-12 justify-content-md-center mt-lg-4'>
          {AdvantagesJSON.map((item: AdvantagesProps) => (
            <div className='col-12 col-md-3' key={item.title}>
              <Card className='d-flex px-3 px-md-2 px-lg-4 py-md-3 py-xl-4 mb-3'>
                {width < WIDTH_MD && (
                  <div className='mr-2'>
                    {
                      item.icon === ''
                      ? <img src={Handshake} />
                      : <OrangeIcon size='SM' color='#9A4517' icon={item.icon} />
                    }
                  </div>
                )}
                <div>
                  {width >= WIDTH_MD
                    ? (
                      <div className='d-flex align-items-md-center mb-2'>
                        <div className='mr-2'>
                          {
                            item.icon === ''
                            ? <img src={Handshake} />
                            : <OrangeIcon size='SM' color='#9A4517' icon={item.icon} />
                          }
                        </div>
                        <h3 className='text-grayscale--400 fw-600 mb-0'>{item.title}</h3>
                      </div>
                    )
                    : <h3 className='text-grayscale--400 fw-600 mb-2'>{item.title}</h3>
                  }
                  <p className='text-grayscale--400 fw-400 mb-0'>
                    {item.description}
                  </p>
                </div>
              </Card>
            </div>
          ))}
        </CardWrapper>
        <div className='row mt-3 mt-md-4 mt-lg-5'>
          <div className='col-12 d-flex justify-content-center'>
            <button
              className='btn btn--lg btn-orange--extra text-white text-none'
              onClick={() => {
                setModalOpen(true)
                sendDatalayerEvent({
                  section: 'dobra_05',
                  element_action: 'click button',
                  section_name: 'Vantagens de receber através da API Pix',
                  element_name: 'Falar com um especialista',
                })
              }}
            >
              Falar com um especialista
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default advantagesToReceiveInter
