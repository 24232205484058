import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import YourCompanyProfitsJSON from '../../assets/YourCompanyProfits.json'
import { Section, CardWrapper } from './style'

type YourCompanyProfitsProps = {
  icon: string;
  title: string;
}

const YourCompanyProfits = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section
      role='img'
      aria-label='Desenvolvedor sorridente com uma caneca em sua mão.'
      className='py-5 d-flex align-items-center'
    >
      <div className='container'>
        <div className='box px-4 py-4'>
          <div className='row align-items-start align-items-md-center mb-md-3 mt-xl -5'>
            <div className='col-12 col-md-5 col-xl-6 offset-md-6 offset-xl-6 content px-md-3 px-xl-5 py-md-4 py-xl-5'>
              <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-28 lh-lg-44 fw-700 font-citrina mb-4'>
                <span className='d-block'>
                  Você desenvolve,
                </span> sua empresa lucra.
              </h2>
              <h3 className='font-inter'>
                O Inter Empresas é parceiro de verdade e oferece:
              </h3>
              <CardWrapper className='mt-lg-4'>
                {YourCompanyProfitsJSON.map((item: YourCompanyProfitsProps) => (
                  <div key={item.title} className='col-12 pl-md-0'>
                    <div className='d-flex mb-0 mb-lg-2 mb-xl-3'>
                      <div className='d-flex align-items-center mb-0'>
                        <div className='mr-2'>
                          <OrangeIcon size='SM' color='#9A4517' icon={item.icon} />
                        </div>
                        <p className='text-grayscale--400 fw-600 mb-0'>{item.title}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </CardWrapper>
              <a
                target='blank'
                href='https://developers.inter.co/' className='btn btn--lg btn-orange--extra text-white text-none mt-3'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_07',
                    element_action: 'click button',
                    section_name: 'Você desenvolve, sua empresa lucra.',
                    element_name: 'Acesse agora',
                  })
                }}
              >
                Acesse agora
              </a>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default YourCompanyProfits
