import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Handshake from '../../assets/images/handshake.png'

import { Section, Card } from './style'

const TypesOfBusiness = () => {
  return (
    <Section className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-5 col-xl-6 offset-md-1 offset-xl-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-28 lh-lg-44 text-graphite fw-700 font-citrina mb-4'>
              Alguns tipos de negócios que se adaptam ao Pix Automático
            </h2>
            <div className='row'>
              <div className='col-6 mb-2 mb-md-3'>
                <Card className='px-3 py-2'>
                  <div className='d-flex align-items-center'>
                    <div className='mr-2'>
                      <OrangeIcon size='SM' color='#9A4517' icon='savings' />
                    </div>
                    <div>
                      <p className='text-grayscale--400 fw-600 mb-0'>
                        Clubes de assinatura
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
              <div className='col-6 mb-2'>
                <Card className='px-3 py-2'>
                  <div className='d-flex align-items-center'>
                    <div className='mr-2'>
                      <OrangeIcon size='SM' color='#9A4517' icon='forward' />
                    </div>
                    <div>
                      <p className='text-grayscale--400 fw-600 mb-0'>
                        Serviços de streaming
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
              <div className='col-6 mb-2 mb-md-3'>
                <Card className='px-3 py-2'>
                  <div className='d-flex align-items-center'>
                    <div className='mr-2'>
                      <OrangeIcon size='SM' color='#9A4517' icon='health' />
                    </div>
                    <div>
                      <p className='text-grayscale--400 fw-600 mb-0'>
                        Plano de saúde
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
              <div className='col-6 mb-2 mb-md-3'>
                <Card className='px-3 py-2'>
                  <div className='d-flex align-items-center'>
                    <div className='mr-2'>
                      <OrangeIcon size='SM' color='#9A4517' icon='home' />
                    </div>
                    <div>
                      <p className='text-grayscale--400 fw-600 mb-0'>
                        Condomínios
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
              <div className='col-6 mb-2 mb-md-3'>
                <Card className='px-3 py-2'>
                  <div className='d-flex align-items-center'>
                    <div className='mr-2'>
                      <OrangeIcon size='SM' color='#9A4517' icon='globe' />
                    </div>
                    <div>
                      <p className='text-grayscale--400 fw-600 mb-0'>
                        Provedor de internet
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
              <div className='col-6 mb-2 mb-md-3'>
                <Card className='px-3 py-2'>
                  <div className='d-flex align-items-center'>
                    <div className='mr-2'>
                      <img className='icon' src={Handshake} />
                    </div>
                    <div>
                      <p className='text-grayscale--400 fw-600 mb-0'>
                        Casas de apostas
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
              <div className='col-6 mb-2 mb-md-3'>
                <Card className='px-3 py-2'>
                  <div className='d-flex align-items-center'>
                    <div className='mr-2'>
                      <OrangeIcon size='SM' color='#9A4517' icon='aprenda-a-investir' />
                    </div>
                    <div>
                      <p className='text-grayscale--400 fw-600 mb-0'>
                        Escolas, faculdade e cursos
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
              <div className='col-6'>
                <Card className='px-3 py-2'>
                  <div className='d-flex align-items-center'>
                    <div className='mr-2'>
                      <OrangeIcon size='SM' color='#9A4517' icon='house-portability' />
                    </div>
                    <div>
                      <p className='text-grayscale--400 fw-600 mb-0'>
                        Distribuidoras de energia e água
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-5 offset-xl-1 d-flex justify-content-center justify-content-md-end mt-5 mt-md-0'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-auto-dobra4/image.webp'
              altDescription='Empreendedora, sentada em um sofá, sorri enquanto usa seu smartphone.'
              arrayNumbers={[ 307, 249, 332, 468 ]}
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default TypesOfBusiness
